import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path === '/') {
    return;
  }
  const cmsStore = cmsContentStore();

  const data = await cmsStore.getPage({ pageIdOrName: to.path });
  to.meta.pageData = data;

  const layout = data?.layout.name === 'Clean' ? 'empty' : 'default';
  to.meta.layout = layout;
  setPageLayout(layout);
});
